'use strict'

angular.module('cb.mailing-service', [])
    .factory('MailingService', ["ApiService", function (ApiService) {

        var mailingService = {
            GetMailerContent: function (data) {
                try {
                    var url = '/webapi/api/v1/Mailing/GetMailerContent/';
                    return ApiService.promisePost(url, data);
                }
                catch (e) {
                    console.error('Error!', e);
                }
            },
            GetMailerContentByCount: function (data) {
                try {
                    var url = '/webapi/api/v1/Mailing/GetMailerContentByMailingCount/' + data;
                    return ApiService.promiseGet(url);
                }
                catch (e) {
                    console.error('Error!', e);
                }
            },
            CreateNewMailerEntry: function (data) {
                try {
                    var url = '/webapi/api/v1/Mailing/CreateNewMailerEntry';
                    return ApiService.promisePost(url, data);
                }
                catch (e) {
                    console.error('Error!', e);
                }
            },
            EditMailerEntry: function (data) {
                try {
                    var url = '/webapi/api/v1/Mailing/EditMailerEntry';
                    return ApiService.promisePost(url, data);
                }
                catch (e) {
                    console.error('Error!', e);
                }
            },
            DeleteMailerEntry: function (data) {
                try {
                    var url = '/webapi/api/v1/Mailing/DeleteMailerEntry';
                    return ApiService.promisePost(url, data);
                }
                catch (e) {
                    console.error('Error!', e);
                }
            },
            GetBuilderList: function (data) {
                try {
                    var url = '/webapi/api/v1/Mailing/GetBuilderList';
                    return ApiService.promisePost(url, data);
                }
                catch (e) {
                    console.error('Error!', e);
                }
            },
            CreateAgent: function (data) {
                try {
                    var url = '/webapi/api/v1/Mailing/CreateAgent';
                    return ApiService.promisePost(url, data);
                }
                catch (e) {
                    console.error('Error!', e);
                }
            },
            EditAgent: function (data) {
                try {
                    var url = '/webapi/api/v1/Mailing/EditAgent';
                    return ApiService.promisePost(url, data);
                }
                catch (e) {
                    console.error('Error!', e);
                }
            },
            GetImportBatch: function (data) {
                try {
                    var url = '/webapi/api/v1/Mailing/GetImportBatch';
                    return ApiService.promisePost(url, data);
                }
                catch (e) {
                    console.error('Error!', e);
                }
            },
            DeleteImportBatch: function(id){
                try {
                    var url = '/webapi/api/v1/Mailing/DeleteImportBatch/' + id;
                    return ApiService.promiseGet(url);
                } catch (e) {
                    console.error('Error!', e);                    
                }
            },
            GetFirstMailing: function (data) {
                try {
                    var url = '/webapi/api/v1/Mailing/GetFirstMailing';
                    return ApiService.promisePost(url, data);
                }
                catch (e) {
                    console.error('Error!', e);
                }
            },
            GetFollowUpMailing: function (data) {
                try {
                    var url = '/webapi/api/v1/Mailing/GetFollowUpMailing';
                    return ApiService.promisePost(url, data);
                }
                catch (e) {
                    console.error('Error!', e);
                }
            },
            SetUpMalingBatch:function (data) {
                try {
                    var url = '/webapi/api/v1/Mailing/SetUpMailingBatch';
                    return ApiService.promisePost(url, data);
                }
                catch (e) {
                    console.error('Error!', e);
                }
			},
			SetUpLeadMalingBatch: function (data) {
				try {
					var url = '/webapi/api/v1/Mailing/SetUpLeadMailingBatch';
					return ApiService.promisePost(url, data);
				}
				catch (e) {
					console.error('Error!', e);
				}
			},
            DownlodeBatches:function (data) {
                try {
                    var url = '/webapi/api/v1/Mailing/downloadmailingSelectionByBatch';
                    return ApiService.promisePost(url, data, {responseType: 'arraybuffer'});
                }
                catch (e) {
                    console.error('Error!', e);
                }
            },
            GetMailingSelectionforProspects : function(data, file){
                try {
                    var url = '/webapi/api/v1/Mailing/GetMailingSelectionByBatch';
                    return ApiService.promiseUpload(url, data, file);
                }
                catch (e) {
                    console.error('Error!', e);
                }
            },
            GetMailingSelectionList: function (data) {
                try {
                    var url = '/webapi/api/v1/Mailing/GetMailingSelectionlist';
                    return ApiService.promisePost(url, data);
                }
                catch (e) {
                    console.error('Error!', e);
                }
			},
			GetAllMailingSelectionList: function (data) {
				try {
					var url = '/webapi/api/v1/Mailing/GetAllMailingSelectionList';
					return ApiService.promisePost(url, data);
				}
				catch (e) {
					console.error('Error!', e);
				}
			},
            GetMailingSelection: function (data, file) {
                try {
                    var url = '/webapi/api/v1/Mailing/GetMailingSelection';
                    return ApiService.promiseUpload(url, data, file);
                }
                catch (e) {
                    console.error('Error!', e);
                }
            },
            GetMailingParams: function () {
                try {
                    var url = '/webapi/api/v1/picklist/getPickListByPickListName';
                    return ApiService.promiseGet(url);
                }
                catch (e) {
                    console.error('Error!', e);
                }
            },
            GetMailingLeads: function (data) {
                try {
                    var url = '/webapi/api/v1/Mailing/GetMailingLeads';
                    return ApiService.promisePost(url, data);
                }
                catch (e) {
                    console.error('Error!', e);
                }
			},
			updateProspects: function (data) {
				try {
					var url = '/webapi/api/v1/Mailing/updateProspects';
					return ApiService.promisePost(url, data);
				} catch (e) {
					console.error('Error!', e);
				}
			},
			CreateMailBatchRecord: function (data) {
				try {
					var url = '/webapi/api/v1/Mailing/CreateMailBatchRecord';
					return ApiService.promisePost(url, data);
				} catch (e) {
					console.error('Error!', e);
				}
			},
			UpdateMailing: function (data) {
				try {
					var url = '/webapi/api/v1/Mailing/UpdateMailing';
					return ApiService.promisePost(url, data);
				} catch (e) {
					console.error('Error!', e);
				}
			},
			UpdateOtherMailing: function (data) {
				try {
					var url = '/webapi/api/v1/Mailing/updateOtherMailings';
					return ApiService.promisePost(url, data);
				} catch (e) {
					console.error('Error!', e);
				}
			}
        };
        return mailingService;
    }]);
